import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  videoItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  videoItemImg: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "block",
      borderRadius: 5,
    },
  },
  videoItemTime: {
    position: "absolute",
    bottom: 5,
    right: 5,
    color: "white",
    background: "rgba(0, 0, 0, 0.7)",
    padding: "1px 4px",
    fontSize: "0.625rem",
    borderRadius: 2,
  },
  videoItemTitle: {
    marginTop: 12,
    paddingLeft: 4,
    lineHeight: 1.2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    display: "-webkit-box",
    textAlign: "left",
    fontSize: "0.75rem",
  },
  bd: {
    width: "100%",
    overflow: "hidden",
  },
}));

const VideoGrid = ({ data, currentUser }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {data.map((item) => {
        return (
          <Grid item xs={6} md={4} lg={3} key={item.id}>
            <ButtonBase
              className={classes.videoItem}
              href={item.url || `/video/${item.id}`}
            >
              <div className={classes.videoItemImg}>
                <img alt={item.title} src={item.thumb} />
                <span className={classes.videoItemTime}>{item.duration}</span>
              </div>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.videoItemTitle}
              >
                {item.title}
              </Typography>
            </ButtonBase>
          </Grid>
        );
      })}
    </Grid>
  );
};

VideoGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

VideoGrid.defaultProps = {
  data: [],
};

export default VideoGrid;
