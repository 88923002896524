import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import BottomNavigation from "../components/BottomNavigation";
import { appName } from "../utils/utils";

const useStyles = makeStyles({
  container: {
    padding: 0,
  },
});

const MainLayout = ({
  hasPivotBar,
  children,
  title,
  description,
  keywords,
}) => {
  const classes = useStyles();

  return (
    <main className="layout" has-pivot-bar={hasPivotBar.toString()}>
      <Head>
        <title>{title ? `${title}_${appName}` : appName}</title>
        {keywords && <meta name="keywords" content={keywords} />}
        {description && <meta name="description" content={description} />}
      </Head>
      {children}
      {hasPivotBar && <BottomNavigation />}
    </main>
  );
};

MainLayout.propTypes = {
  hasPivotBar: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

MainLayout.defaultProps = {
  hasPivotBar: false,
  title: "",
  description: "",
};

export default MainLayout;
