import React from "react";
import Link from "next/link";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import MicIcon from "@material-ui/icons/Mic";
import InstagramIcon from "@material-ui/icons/Instagram";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import MainLayout from "../components/MainLayout";
import Header from "../components/Header";
import VideoColumns from "../components/VideoGrid";
import { apiUrl } from "../utils/utils";
const appBanner = `${process.env.STATIC_URL}/images/app.jpg`;

const useStyles = makeStyles((theme) => ({
  mainNav: {
    margin: "16px 0 0",
    textAlign: "center",
  },
  mainNavTitle: {
    display: "block",
    marginTop: theme.spacing(1),
    fontSize: "0.75rem",
  },
  mainNavButton: {
    background: "#535353",
    color: "#fff",
  },
  footer: {
    textAlign: "center",
    color: theme.palette.grey["500"],
    padding: "16px 24px",
  },
  footerText: {
    fontSize: "0.625rem",
    "& a": {
      color: theme.palette.grey["500"],
      textDecoration: "none",
    },
  },
  divider: {
    margin: "16px 0 8px",
  },
  alert: {
    display: "flex",
    padding: "6px 16px",
    margin: "8px 16px",
    backgroundColor: "#4caf50",
    fontWeight: 500,
    color: "#fff",
    borderRadius: 4,
  },
  alertMessage: {
    padding: "8px 0",
    width: "100%",
    color: "#fff",
    textDecoration: "none",
  },
}));

function IndexPage({ recommend, newest, currentUser }) {
  const classes = useStyles();

  return (
    <MainLayout hasPivotBar={true} title="ASMR助眠哄睡视频音频资源分享网站">
      {/*顶部导航栏*/}
      <Header />
      <a href="https://app.qqasmr.com">
        <img src={appBanner} alt="番茄助眠app" style={{ width: '100%', display: 'block' }} />
      </a>
      {/*<div className={classes.alert}>*/}
      {/*  <a className={classes.alertMessage} href="https://v.qqasmr.com">*/}
      {/*    <Typography variant="subtitle2" gutterBottom>*/}
      {/*      🍅番茄ASMR高级版现已发布！🎉🎉🎉*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="subtitle2" gutterBottom>*/}
      {/*      链接地址：*/}
      {/*      <strong style={{ fontSize: "1.2rem" }}>v.qqasmr.com‍</strong>*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="caption">*/}
      {/*      速度更快⚡ 体验更爽🚀 功能更多💎{" "}*/}
      {/*    </Typography>*/}
      {/*  </a>*/}
      {/*</div>*/}
      {/*首页导航栏*/}
      <div className={classes.mainNav}>
        <Box display="flex" style={{ padding: "8px 0" }}>
          <Box flexGrow={1}>
            <Fab
              color="secondary"
              aria-label="add"
              className={classes.mainNavButton}
              href={"/artist"}
            >
              <MicIcon fontSize="large" />
            </Fab>
            <Link href={"/artist"}>
              <Typography
                component="a"
                variant="body2"
                className={classes.mainNavTitle}
              >
                ASMR主播
              </Typography>
            </Link>
          </Box>
          <Box flexGrow={1}>
            <Fab
              color="secondary"
              aria-label="photo"
              className={classes.mainNavButton}
              href={"/photo"}
            >
              <InstagramIcon fontSize="large" />
            </Fab>
            <Link href={"/photo"}>
              <Typography
                component="a"
                variant="body2"
                className={classes.mainNavTitle}
              >
                ins动态
              </Typography>
            </Link>
          </Box>
          <Box flexGrow={1}>
            <Fab
              color="secondary"
              aria-label="add"
              className={classes.mainNavButton}
              href={"/trigger"}
            >
              <AudiotrackIcon fontSize="large" />
            </Fab>
            <Link href={"/trigger"}>
              <Typography
                component="a"
                variant="body2"
                className={classes.mainNavTitle}
              >
                触发音
              </Typography>
            </Link>
          </Box>
          {/*<Box flexGrow={1}>*/}
          {/*  <Fab*/}
          {/*    color="secondary"*/}
          {/*    aria-label="rank"*/}
          {/*    className={classes.mainNavButton}*/}
          {/*    href={"/video?order=hot"}*/}
          {/*  >*/}
          {/*    <EqualizerIcon fontSize="large" />*/}
          {/*  </Fab>*/}
          {/*  <Link href={"/video?order=hot"}>*/}
          {/*    <Typography*/}
          {/*      component="a"*/}
          {/*      variant="body2"*/}
          {/*      className={classes.mainNavTitle}*/}
          {/*    >*/}
          {/*      排行榜*/}
          {/*    </Typography>*/}
          {/*  </Link>*/}
          {/*</Box>*/}
        </Box>
      </div>

      <Box p={2} pb={3}>
        {/*首页推荐*/}
        <Box display="flex" alignItems="center" style={{ padding: "8px 0" }}>
          <Box flexGrow={1}>
            <Typography variant="subtitle1">首页推荐</Typography>
          </Box>
          <Button size="small" href={"/video?order=recommend"}>
            更多
            <NavigateNextOutlinedIcon fontSize="small" />
          </Button>
        </Box>
        {recommend && <VideoColumns data={recommend} />}

        <Divider component="hr" light className={classes.divider} />

        {/*最新发布*/}
        <Box display="flex" alignItems="center" style={{ padding: "8px 0" }}>
          <Box flexGrow={1}>
            <Typography variant="subtitle1">最新上传</Typography>
          </Box>
          <Button size="small" href={"/video?order=newest"}>
            更多
            <NavigateNextOutlinedIcon fontSize="small" />
          </Button>
        </Box>
        {newest && <VideoColumns data={newest} />}

        {/*<Divider component="hr" light className={classes.divider} />*/}
        {/*观看最多*/}
        {/*<Box display="flex" alignItems="center" style={{ padding: "8px 0" }}>*/}
        {/*  <Box flexGrow={1}>*/}
        {/*    <Typography variant="subtitle1">观看最多</Typography>*/}
        {/*  </Box>*/}
        {/*  <Link href={"/video?order=hot"}>*/}
        {/*    <Button size="small">*/}
        {/*      更多*/}
        {/*      <NavigateNextOutlinedIcon fontSize="small" />*/}
        {/*    </Button>*/}
        {/*  </Link>*/}
        {/*</Box>*/}
        {/*<VideoColumns data={props.hotVideos} />*/}
      </Box>
      <Divider component="hr" light />
      {/*底部声明*/}
      <div className={classes.footer}>
        <Typography variant="body2" gutterBottom>
          &copy; 2020 🍅番茄ASMR
        </Typography>
        <Typography
          variant="caption"
          component="p"
          gutterBottom
          className={classes.footerText}
        >
          本站所有视频和音频均收集于互联网，版权归属原创作者，如有侵权请邮件通知我们：admin@qqasmr.com
        </Typography>
      </div>
    </MainLayout>
  );
}

export async function getServerSideProps() {
  const res = await fetch(`${apiUrl}/v1/video/home`);
  const json = await res.json();

  if (json.data) {
    return {
      props: {
        newest: json.data.newest,
        recommend: json.data.recommend,
      },
    };
  }
  return { props: {} };
}

export default IndexPage;
