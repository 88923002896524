import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    left: 0,
    bottom: 0,
    right: 0,
    padding:
      "0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
    background: "#282828"
  },
  action: {
    color: "hsla(0,0%,93.3%,.6)",
    "&$selected": {
      color: "#fff"
    }
  },
  selected: {}
});

export default function SimpleBottomNavigation() {
  const router = useRouter();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (router.pathname === "/") {
      setValue(0);
    }
    if (router.pathname === "/trigger") {
      setValue(1);
    }
    if (router.pathname === "/subscription") {
      setValue(2);
    }
    if (router.pathname === "/user") {
      setValue(3);
    }
  }, [value]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        classes={{
          root: classes.action,
          selected: classes.selected
        }}
        label="首页"
        icon={<HomeIcon />}
        href="/"
        disabled={router.pathname === "/"}
      />
      <BottomNavigationAction
        classes={{
          root: classes.action,
          selected: classes.selected
        }}
        label="触发音"
        icon={<AudiotrackIcon />}
        href="/trigger"
        disabled={router.pathname === "/trigger"}
      />
      <BottomNavigationAction
        classes={{
          root: classes.action,
          selected: classes.selected
        }}
        label="订阅内容"
        icon={<SubscriptionsIcon />}
        href="/subscription"
        disabled={router.pathname === "/subscription"}
      />
      <BottomNavigationAction
        classes={{
          root: classes.action,
          selected: classes.selected
        }}
        label="我的"
        icon={<PersonIcon />}
        href="/user"
        disabled={router.pathname === "/user"}
      />
    </BottomNavigation>
  );
}
