import React, { useState } from "react";
import Router from "next/router";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import WechatDialog from "../components/WechatDialog";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 56,
    padding: "12px 0",
    display: "block",
  },
  avatar: {
    width: 24,
    height: 24,
  },
}));

const Header =  () => {
  const classes = useStyles();
  const [openWechat, setOpenWechat] = useState(false);

  const handleWechatClick = () => {
    setOpenWechat(true);
  };

  return (
    <>
      <AppBar position="fixed" elevation={1} color="default">
        <Toolbar>
          <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
            noWrap
            onClick={() => Router.push("/")}
          >
            <img
              alt="番茄ASMR"
              src={`${process.env.STATIC_URL}/images/logo.png`}
              className={classes.logo}
            />
          </Typography>
          <IconButton
            aria-label="search"
            color="inherit"
            onClick={() => Router.push("/search")}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            aria-label="qq"
            color="inherit"
            onClick={handleWechatClick}
          >
            <SvgIcon className="iconfont">
              <use xlinkHref="#icon-qq" />
            </SvgIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <WechatDialog isOpen={openWechat} onClose={() => setOpenWechat(false)} />
    </>
  );
};

export default Header;
