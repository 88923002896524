import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const qunData = [
  {
    id: 956929552,
    name: "助眠音频分享①群",
    url: "https://jq.qq.com/?_wv=1027&k=YlTJVO4n",
  },
  {
    id: 427682806,
    name: "助眠音频分享②群",
    url: "https://jq.qq.com/?_wv=1027&k=qmGnCZsy",
  },
  {
    id: 878812599,
    name: "助眠音频分享③群",
    url: "https://jq.qq.com/?_wv=1027&k=9DddkbRM",
  },
];

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      textAlign: "center",

      "& img": {
        width: "100%",
        display: "block",
        margin: "0 auto 8px",
      },
    },
  })
);

export default function WechatDialog({ isOpen, onClose }) {
  const classes = useStyles();

  function handleClose() {
    onClose();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="login-dialog-title"
      aria-describedby="login-dialog-description"
    >
      <DialogContent>
        <List component="nav">
          {qunData.map((item) => (
            <ListItem button component="a" key={item.id} href={item.url}>
              <ListItemText primary={item.name} />
              <KeyboardArrowRight />
            </ListItem>
          ))}
        </List>
        {/*<Divider style={{ margin: "8px 0 16px" }} light />*/}
        {/*<Grid container spacing={4} component="div">*/}
        {/*  <Grid item xs={6} className={classes.item}>*/}
        {/*    <img alt="明星爱豆" src="/static/images/bella_wx.jpg" />*/}
        {/*    <Typography variant="body2" color="inherit">*/}
        {/*      公众号*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} className={classes.item}>*/}
        {/*    <img alt="图贝拉" src="/static/images/bella_app.jpg" />*/}
        {/*    <Typography variant="body2" color="inherit">*/}
        {/*      看图神器*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" fullWidth>
          关闭
        </Button>
      </DialogActions>
    </Dialog>
  );
}
